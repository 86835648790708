.nav-styles {
    align-items: center;
    background-color: white;
    /* border-radius: 35px; */
    box-sizing: border-box;
    display: flex;
    height: 110px;
    justify-content: center;
    left: 50%;
    margin: 0;
    overflow: hidden;
    padding: 65px 50px;
    position: fixed;
    top: 0;
    transform: translate(-50%, 0);
    /* transition: border-color 0.4s ease; */
    width: 100%;
    z-index: 100;
}

.nav-inner-styles {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.title-left-styles-heading {
    font-size: var(--font-size-h5);
    width: 50vw;
}

.ul-list-right-styles {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    justify-content: space-between;
    line-height: 1;
    list-style-type: none;
    width: 50vw;
}

.ul-list-right-item-styles {
    display: flex;
    margin: 0 5px 0;
    text-decoration: none;
}

.ul-list-right-item-styles a {
    transition: color 0.3s ease;
}

@media (max-width: 768px) {
    .nav-styles {
        padding: 5px 20px;
        height: 90px;
    }

    .title-left-styles-heading {
        font-size: var(--font-size-h6);
        margin-left: 5px;
        width: 27vw;
    }

    .ul-list-right-styles {
        align-content: flex-end;
        padding: 0 0 0 20px;
        width: 83vw;
    }

    .ul-list-right-item-styles {
        margin-left: 5px;
    }
}
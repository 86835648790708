.section-styles-deniro {
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  overflow: hidden;
}

.press-header-deniro {
  color: white;
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .section-styles-deniro {
    padding: 10px 50px 50px;
  }
}
.section-styles-footer {
  align-content: left;
  align-items: left;
  background-color: var(--color-text-tan);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: left;
  width: 100%;
  padding: 50px;
}

.title-styles {
  color: var(--color-dark);
  height: auto;
  text-align: left;
  font-weight: 700;
  line-height: 1;
}

.contact-styles {
  line-height: 1.5;
}
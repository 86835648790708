.bio-container {
  display: flex;
  flex-direction: row;
  height: auto;
  overflow: hidden;
}

.bio-column {
  flex: 1;
}

.bio-image-column {
  background-image: url("./img/9.jpg");
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
  opacity: 0.1;
  transform: scaleX(-1);
}

.bio-text-column {
  align-items: start;
  color: white;
  display: flex;
  justify-content: center;
  padding: 40px 40px;
  box-sizing: border-box;
  width: 100vw;
}

.bio-text-column-inner {
  z-index: 1;
}

.bio-text-heightened {
  color: var(--color-text-black);
  font-weight: 800;
}

@media (max-width: 768px) {
  .bio-container {
    flex-direction: column;
    height: auto;
    min-height: auto;
    width: 100vw;
  }

  .bio-image-column,
  .bio-text-column {
    height: 50vh;
    padding: 0 50px;
    width: 100%;
    
  }

  .bio-text {
    font-size: var(--font-size-h3);
    margin-top: 35px;
  }
}

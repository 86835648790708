.section-styles-press {
    background-color: rgb(246, 246, 246);
    box-sizing: border-box;
    height: auto;
    margin-bottom: 0;
    min-height: 100vh;
    padding: 45px 60px;
    width: 100%;
}

/* Grid.css */

.press-header {
    color: var(--color-ark);
    text-decoration: underline;
}
.press-grid-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.press-grid-item {
    box-sizing: border-box;
    flex: 0 1 50%;
    padding: 0 50px 50px 0;
}

.press-grid-item sub {
    display: block;
    margin-bottom: 15px;
}

.press-grid-item a {
    color: var(--color-dark);
    text-decoration: none;
}

.press-grid-item a:hover {
    text-decoration: underline;
}

.press-quote {
    font-weight: 500;
}

@media (max-width: 768px) {
    .press-grid-item {
        flex: 1 1 100%;
        padding: 0 0 50px;
    }
}
.App {
  box-sizing: border-box;
  position: relative;
  text-align: left;
  font-size: 1.75rem;
  line-height: 1.15em;
}

.main {
  background-color: transparent;
  height: auto;
  position: absolute;
  width: 100%;
  z-index: 10;
}

/* .gallery-container-wrapper-styles {
  box-sizing: border-box;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  background: teal;
} */
.section-styles-intro {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 120px 0 90px;
  width: 100%;
}

.column {
  box-sizing: border-box;
  display: flex;
  padding: 20px 0 20px;
  align-items: center;
  justify-content: center;
}

.left-column {
  background-color: white;
  width: 60%;
  justify-content: flex-start;
  padding-left: 50px;
}

.right-column {
  background-color: white;
  width: 40%;
}

.intro-text-wrapper-styles {
  max-width: 80%;
}

.intro-text-item-styles {
  color: var(--color-text-black);
  font-size: var(--font-size-h1);
  line-height: 1.3;
  margin: 0;
  text-align: left;
}

.image-wrapper {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  width: 30vw;
}

.scroll-image-4 {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

@media (max-width: 768px) {
  .section-styles-intro {
    flex-direction: column;
    padding: 0 0 90px 0;
  }

  .column {
    width: 100%;
  }

  .left-column {
    padding: 60px 50px 0;
  }

  .right-column {
    margin-top: 20%;
    order: -1; /* This makes the right column appear on top on mobile */
    padding: 1cqmin 50px 0;
  }

  .intro-text-item-styles {
    font-size: var(--font-size-h3);
    line-height: 1.4;
    text-align: center;
  }

  .image-wrapper {
    width: 65vw;
  }

  .intro-text-wrapper-styles {
    max-width: 100%;
  }
}
.section-styles-table {
  box-sizing: border-box;
  padding: 20px;
  width: 100vw;
}

.filmography-header {
  color: var(--color-lighter-gray);
  text-decoration: underline;
  margin-left: 50px;
}

.section-row {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.section-col {
  width: 100%;
  margin-bottom: 20px;
}

.column__item {
  margin-bottom: 20px;
}

.column__item-imgwrap {
  position: relative;
  width: 100%;
  padding-top: 150%;
  /* 2:3 aspect ratio */
  overflow: hidden;
}

.column__item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.column__item-img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: inherit;
  background-color: transparent;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.column__item-img:hover::after {
  transform: scale(1.05);
}

.column__item-caption {
  color: white;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: clamp(0.4rem, 0.8vw, 0.8125rem);
}

.column__item-caption p {
  padding: 0;
  margin: 0;
}

.column__item-caption span {
  display: block;
}

.caption-role {
  font-weight: 700;
  font-size: clamp(0.5rem, 0.6vw, 0.8125rem);
}

.caption-meta {
  font-size: clamp(0.6rem, 0.9vw, 0.8125rem);
}

.disabled {
  cursor: default !important;
}

.trailer-card__wrapper {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-align: left;
  width: 100%;
}

/* Desktop styles (3 columns) */
@media screen and (min-width: 768px) {
  .section-col {
    width: calc(33.33% - 20px);
    margin-bottom: 40px;
  }
}

@media (min-width:768px) and (max-width:1240px) {
  .column__item-caption {
    flex-direction: column;
  }

  .column__item {
    margin: 25px 50px 0;
  }

  .column__item p {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .filmography-header {
    margin-left: 50px;
  }

  .column__item {
    margin: 25px 50px;
  }

  .section-styles-table {
    padding: 20px 0;
  }
}